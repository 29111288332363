import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import Xxx50DayMaChartTemplate from '@/templates/crypto/xxx-50-day-moving-average-chart';

export default function Solana50DayMaChart({ location }: GatsbyPageProps) {
  return (
    <Xxx50DayMaChartTemplate
      location={location}
      coinAbbreviation="SOL"
      coinFullName="Solana"
      token={Token.SOLUSDT}
      articleId={ArticleList.SOLANA_50_DAY_MOVING_AVERAGE_CHART}
    ></Xxx50DayMaChartTemplate>
  );
}
